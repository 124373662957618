// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rxRJ_{display:flex}.NizR2{display:inline-flex}.JsL3F{flex-direction:column}.wT7fc{align-items:flex-start}.GhCDl{align-items:center}.ciZEY{align-items:flex-end}.aA0ex{justify-content:start}.gyNvS{justify-content:center}.j6fdL{justify-content:flex-end}.Styux{justify-content:space-between}.in6ja{justify-content:space-around}.gysNk{flex-wrap:nowrap}.YlmlN{flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,mBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,oBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,wBAAA,CAGF,OACE,6BAAA,CAGF,OACE,4BAAA,CAGF,OACE,gBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-start {\n  align-items: flex-start;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-start {\n  justify-content: start;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `rxRJ_`,
	"inline": `NizR2`,
	"column": `JsL3F`,
	"align-start": `wT7fc`,
	"align-center": `GhCDl`,
	"align-end": `ciZEY`,
	"justify-start": `aA0ex`,
	"justify-center": `gyNvS`,
	"justify-end": `j6fdL`,
	"justify-between": `Styux`,
	"justify-around": `in6ja`,
	"nowrap": `gysNk`,
	"wrap": `YlmlN`
};
export default ___CSS_LOADER_EXPORT___;
